import React from 'react'
import {StyleSheet, TextProps} from 'react-native'
import Svg, {
  Defs,
  LinearGradient,
  Path,
  PathProps,
  Stop,
  SvgProps,
} from 'react-native-svg'
import {Image} from 'expo-image'

import {colors} from '#/lib/styles'
import {useKawaiiMode} from '#/state/preferences/kawaii'

const ratio = 57 / 64

type Props = {
  fill?: PathProps['fill']
  style?: TextProps['style']
} & Omit<SvgProps, 'style'>

export const Logo = React.forwardRef(function LogoImpl(props: Props, ref) {
  const {fill, ...rest} = props
  const gradient = fill === 'sky'
  const styles = StyleSheet.flatten(props.style)
  const _fill = gradient ? 'url(#sky)' : fill || styles?.color || colors.blue3
  // @ts-ignore it's fiiiiine
  const size = parseInt(rest.width || 32)

  const isKawaii = useKawaiiMode()

  if (isKawaii) {
    return (
      <Image
        source={
          size > 100
            ? require('../../../assets/kawaii.png')
            : require('../../../assets/kawaii_smol.png')
        }
        accessibilityLabel="Bluesky"
        accessibilityHint=""
        accessibilityIgnoresInvertColors
        style={[{height: size, aspectRatio: 1.4}]}
      />
    )
  }

  return (
    <Svg
      fill="none"
      // @ts-ignore it's fiiiiine
      ref={ref}
      viewBox="0 0 113 123"
      {...rest}
      style={[{width: size, height: size * ratio}, styles]}>
      {gradient && (
        <Defs>
          <LinearGradient id="sky" x1="0" y1="0" x2="0" y2="1">
            <Stop offset="0" stopColor="#0A7AFF" stopOpacity="1" />
            <Stop offset="1" stopColor="#59B9FF" stopOpacity="1" />
          </LinearGradient>
        </Defs>
      )}

      <Path
        fill={_fill}
        d="M109.47,47.96c-3.14,2.32-6.16,3.96-7.04,8.01c-1.07,4.9,4.24,12.08,3.87,20.77 c-0.72,16.64-11.6,26.55-31.04,26.56c-9.38,0.01-19.49-1.6-26.77,1.03c-7.97,2.88-12.98,9.85-17.83,18.55 c-6.86-0.62-9.15-4.39-8.21-11.54c-10.13,12.32-24.4-7.44-12.94-17.33C2.4,90.45-1.47,84.87,0.52,75.65 c12.57,15.74,30.65,14.23,51.53,3.93c-18.16-2.92-30.89-12.12-33.05-33.55C12.8,32.64,12,19.56,18.34,6.88 c0.63-1.27,2.18-4.71,3.82-4.64c0.56,0.02,1.07,0.46,1.53,1.37C25.61,9.55,29.04,15.15,34.56,20 c17.55,15.4,34.38,10.86,49.77,37.17c0.12-0.81,0.23-1.64,0.34-2.46c-4.99-8.16-11.01-15.06-18.7-19.55 c-7.33-4.29-15.43-6.63-22.89-11.45c-5.39-3.48-10.41-7.23-14.02-12.47c1.31-1.16,2.82-2.54,3.89-3.96 c1.53-2.04,2.07-4.07,3.79-6.03c0.75-0.85,1.61-1.51,2.39-1.16c0.66,0.3,1.22,1.24,1.68,2.8c7.79,20.37,26.67,21.88,35.45,29.65 c3.84,3.4,7.2,8.62,9.56,14.85c0.26-1.16,0.57-2.3,0.97-3.4c1.88-5.16,6.13-9.94,12.12-9.14c1.49,0.2,2.96,0.76,4.31,1.69 c0.62,0.42,1.2,0.93,1.76,1.52c0.83,0.89,1.59,1.97,2.26,3.27l1.92,1.66l1.09,0.95C114.07,47.04,112.58,45.66,109.47,47.96 L109.47,47.96z M101.18,40.56c0.79,0,1.44,0.64,1.44,1.44c0,0.79-0.64,1.44-1.44,1.44s-1.44-0.64-1.44-1.44 C99.74,41.2,100.39,40.56,101.18,40.56L101.18,40.56z"
      />
    </Svg>
  )
})
